<template>
  <v-card
    class="mx-auto wrapper"
    flat
    >
    <slot>

  	</slot>
  </v-card>
</template>

<script>
  export default {
    name: 'Wrapper',

    data: () => ({

    }),
    computed: {

    },
    methods: {

    },
  };
</script>

<style lang="scss">
@import '@/styles/_variables';
  .wrapper {
    width: 80%;
    max-width: 1400px;
  }


  @media (max-width: 960px) {
    .wrapper {
      width: 100%;
    }
  }
</style>
