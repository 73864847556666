export default [{
    id: 1,
    title: 'Business card 1',
    img: '',
    price: 25.00
  },
  {
    id: 2,
    title: 'Business card 2',
    img: '',
    price: 25.00
  },
  {
    id: 3,
    title: 'Presentation Folder',
    img: '',
    price: 50.00
  }
]
