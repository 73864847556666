
export const languages = [{
    text: 'English',
    value: 'En',
    iconSrc: require(`@/assets/top-menu/icons8-great-britain-80.png`),
    iconSrcUs: require(`@/assets/top-menu/icons8-usa-80.png`)
  },
  {
    text: 'Русский',
    value: 'Ru',
    iconSrc: require('@/assets/top-menu/icons8-russian-federation-80.png')
  },
  {
    text: 'Deutsch',
    value: 'De',
    iconSrc: require('@/assets/top-menu/icons8-germany-80.png')
  },
  {
    text: 'Français',
    value: 'Fr',
    iconSrc: require('@/assets/top-menu/icons8-france-80.png')
  },
  {
    text: 'Ελληνικά',
    value: 'Gr',
    iconSrc: require('@/assets/top-menu/icons8-greece-80.png')
  },
];



