<template>
  <div>
    <Banner />
    <Order />

  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Create from '@/views/create/Create.vue';
  import Order from '@/views/order/Order.vue';
  import Banner from '@/components/Banner.vue'

  export default {
    name: 'MainPage',
    components: {
      Create,
      Order,
      Banner
    },

    data: () => ({

    }),
    computed: {
      ...mapState({
        currentLanguage: (state) => state.language.currentLanguage,
      }),
    },
    methods: {

    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables';

</style>
