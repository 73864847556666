const products = [{
    id: 1,
    productTypeId: 1,
    price: 50.00,
    product: 'business-cards',
    titleEn: 'Business Cards',
    titleRu: 'Визитная карточка',
    titleFr: 'Carte de visite',
    titleDe: 'Visitenkarte',
    titleGr: 'Κάρτα επισκέψεων',
    icon: 'mdi-card-account-details-outline',
    color: 'green darken-2',
    iconSrcEn: require('@/assets/order/business-card/Business-card.webp'),
    briefRu: `Малая некнижная форма печати, традиционный носитель контактной информации о человеке или организации`,
    briefEn: `Cards bearing business information about a company or individual`,
    briefFr: `Un petit format d'impression non livret, un support traditionnel pour les 
            informations de contact sur une personne ou une organisation`,
    briefDe: `Ein kleines nicht gebundenes Druckformat, ein traditionelles Medium für 
            Kontaktinformationen über eine Person oder Organisation`,
    briefGr: `Ένα μικρό μη βιβλιαράκι μορφής εκτύπωσης, ένα παραδοσιακό μέσο για πληροφορίες 
            επαφής σχετικά με ένα άτομο ή μια οργάνωση`,
    textEn: `Cards bearing business information about a company or individual. They are 
            shared during formal introductions as a convenience and a memory aid. 
            A business card typically includes the giver's name, company or business 
            affiliation (usually with a logo) and contact information such as street 
            addresses, telephone number(s), e-mail addresses and website.`,
    textRu: `Карточки с деловой информацией о компании или частном лице. Ими делятся во 
            время официальных представлений и мероприятий. Визитная карточка обычно
            включает в себя имя владельца, компанию или должность (обычно с логотипом) 
            и контактную информацию, такую как адреса, номера телефонов, адреса 
            электронной почты и веб-сайт.`,
    textFr: `Des cartes avec des informations commerciales sur une entreprise ou une personne 
            physique. Elles sont échangées lors des présentations officielles et des événements. 
            Une carte de visite comprend généralement le nom du propriétaire, l'entreprise ou le poste 
            occupé (généralement avec un logo), ainsi que les informations de contact telles que les 
            adresses, les numéros de téléphone, les adresses e-mail et le site web.`,
    textDe: `Karten mit geschäftlichen Informationen über ein Unternehmen oder eine Privatperson. 
            Sie werden während offizieller Vorstellungen und Veranstaltungen ausgetauscht. 
            Eine Visitenkarte enthält in der Regel den Namen des Inhabers, das Unternehmen oder die 
            Position (in der Regel mit einem Logo) und Kontaktdaten wie Adressen, Telefonnummern, 
            E-Mail-Adressen und Website.`,
    textGr: `Κάρτες με επαγγελματικές πληροφορίες για μια εταιρεία ή ένα άτομο. Ανταλλάσσονται 
            κατά τη διάρκεια επίσημων παρουσιάσεων και εκδηλώσεων. Μια κάρτα επισκέψεων περιλαμβάνει 
            συνήθως το όνομα του κατόχου, την εταιρεία ή τη θέση (συνήθως με ένα λογότυπο) και 
            πληροφορίες επικοινωνίας όπως διευθύνσεις, αριθμούς τηλεφώνου, διευθύνσεις ηλεκτρονικού 
            ταχυδρομείου και ιστότοπο.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: "/download/business-card/business-card.pdf",
                Ru: '/download/business-card/business-card.pdf',
                Fr: '/download/business-card/business-card.pdf',
                De: '/download/business-card/business-card.pdf',
                Gr: '/download/business-card/business-card.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/business-card/business-card.ai',
                Ru: '/download/business-card/business-card.ai',
                Fr: '/download/business-card/business-card.ai',
                De: '/download/business-card/business-card.ai',
                Gr: '/download/business-card/business-card.ai',
            }
        }
    ],
    isExample: true,
    options: [1, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: true,
    orderOrder: 1,
    createOrder: 1
  },
  {
    id: 2,
    productTypeId: 2,
    price: 77.77,
    product: 'invitation-cards',
    titleEn: 'Invitation Cards',
    titleRu: 'Приглашения',
    titleFr: 'Invitations',
    titleDe: 'Einladungen',
    titleGr: 'Προσκλήσεις',
    icon: 'mdi-card-account-details-star-outline',
    color: 'purple darken-2',
    iconSrcEn: require('@/assets/order/invitation_card/invitation_card.jpg'),
    briefRu: `Пригласительные билеты предназначены для личного обращения и приглашения на 
        определенное мероприятие, с указанием даты и места проведения.`,
    briefEn: `Invitation cards are intended for personal appeal and an invitation to a 
        specific event, indicating the date and place of the event.`,
    briefFr: `Les billets d'invitation sont destinés à une communication personnelle et à inviter des personnes à un événement spécifique, en indiquant la date et le lieu.`,
    briefDe: `Einladungstickets dienen der persönlichen Kommunikation und Einladung zu einer bestimmten Veranstaltung unter Angabe von Datum und Veranstaltungsort.`,
    briefGr: `Τα εισιτήρια πρόσκλησης προορίζονται για προσωπική επικοινωνία και πρόσκληση σε συγκεκριμένη εκδήλωση, με αναγραφή ημερομηνίας και τόπου διεξαγωγής.`,
    textEn: `Invitation cards are successfully used during weddings, concerts, exhibitions, 
        receptions, presentations. Invitations to corporate events contain company symbols and 
        are one of the forms of advertising.`,
    textRu: `Пригласительные билеты с успехом используются при проведении свадеб, концертов, 
        выставок, фуршетов, презентаций. Пригласительные на корпоративные мероприятия содержат 
        фирменную символику и являются одной из форм рекламы.`,
    textFr: `Les billets d'invitation sont couramment utilisés pour les mariages, les concerts, les expositions, les réceptions, les présentations. Les invitations aux événements d'entreprise incluent l'identité visuelle de l'entreprise et constituent une forme de publicité.`,
    textDe: `Einladungstickets werden erfolgreich für Hochzeiten, Konzerte, Ausstellungen, Empfänge, Präsentationen verwendet. Einladungen zu Firmenveranstaltungen enthalten das Firmenbranding und dienen als Werbeform.`,
    textGr: `Τα εισιτήρια πρόσκλησης χρησιμοποιούνται με επιτυχία για γάμους, συναυλίες, εκθέσεις, δεξιώσεις, παρουσιάσεις. Οι προσκλήσεις για εταιρικά γεγονότα περιλαμβάνουν την εταιρική εικόνα και αποτελούν μια μορφή διαφήμισης.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/invitation_card/invitation_card.pdf',
                Ru: '/download/invitation_card/invitation_card.pdf',
                Fr: '/download/invitation_card/invitation_card.pdf',
                De: '/download/invitation_card/invitation_card.pdf',
                Gr: '/download/invitation_card/invitation_card.pdf',
            }
        },
        {
            name: 'psd',
            files: {
                En: '/download/invitation_card/invitation_card.psd',
                Ru: '/download/invitation_card/invitation_card.psd',
                Fr: '/download/invitation_card/invitation_card.psd',
                De: '/download/invitation_card/invitation_card.psd',
                Gr: '/download/invitation_card/invitation_card.psd',
            }
        }
    ],
    isExample: true,

    options: [1, 2, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: true,
    orderOrder: 2,
    createOrder: 2
  },
  {
    id: 3,
    productTypeId: 3,
    price: 150.00,
    product: 'presentation-folders',
    titleEn: 'Presentation Folders',
    titleRu: 'Буклеты',
    titleFr: 'Brochures Prospectus ja dumaju',
    titleDe: 'Broschüren',
    titleGr: 'Φάκελοι Παρουσίασης',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/presentations/Presentations.jpg'),
    briefRu: `В форме буклета издаются краткие путеводители, рекламные проспекты, программы публичных мероприятий и т. п.`,
    briefEn: `Short guides, brochures, programs of public events, etc. are published in the form of a booklet.`,
    briefFr: `Les brochures sont utilisées pour des guides succincts, des dépliants promotionnels, des programmes d'événements publics, etc.`,
    briefDe: `Broschüren werden für kurze Reiseführer, Werbeflyer, Veranstaltungsprogramme usw. verwendet.`,
    briefGr: `Τα φυλλάδια χρησιμοποιούνται για σύντομους οδηγούς, διαφημιστικά φυλλάδια, προγράμματα δημόσιων εκδηλώσεων κ.λπ.`,
    textEn: `A sheet, as a rule, with two or three folds, folded in various ways, for 
            example: in half, accordion, delta-shaped or in any other way that emphasizes 
            the individuality of the promotional product.
            In the form of a booklet, short guides, advertising brochures, programs of 
            public events, etc. are published`,
    textRu: `Лист, как правило, с двумя или тремя фальцами, сложенный всевозможными способами,
            например: пополам, гармошкой, дельтообразно или любым другим способом, подчёркивающим 
            индивидуальность рекламного продукта. В форме буклета издаются краткие путеводители, 
            рекламные проспекты, программы публичных мероприятий и т. п.`,
    textFr: `Une feuille, généralement avec deux ou trois plis, pliée de différentes manières, comme un pli en deux, un pli accordéon, un pli delta ou toute autre méthode qui met en valeur l'individualité du produit promotionnel. Les brochures sont utilisées pour des guides succincts, des dépliants promotionnels, des programmes d'événements publics, etc.`,
    textDe: `Ein Blatt, normalerweise mit zwei oder drei Falzen, gefaltet auf verschiedene Arten wie zum Beispiel im Halbformat, Akkordeonfaltung, Deltafaltung oder auf andere Weise, um die Individualität des Werbeprodukts zu betonen. Broschüren werden für kurze Reiseführer, Werbeflyer, Veranstaltungsprogramme usw. verwendet.`,
    textGr: `Ένα φύλλο, συνήθως με δύο ή τρεις πτυχώσεις, που πτυγμένο σε διάφορους τρόπους, όπως στα μισά, ακορντεόν, ντέλτα ή με οποιονδήποτε άλλο τρόπο που τονίζει την ατομικότητα του διαφημιστικού προϊόντος. Τα φυλλάδια χρησιμοποιούνται για σύντομους οδηγούς, διαφημιστικά φυλλάδια, προγράμματα δημόσιων εκδηλώσεων κ.λπ.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/presentations/Presentation.pdf',
                Ru: '/download/presentations/Presentation.pdf',
                Fr: '/download/presentations/Presentation.pdf',
                De: '/download/presentations/Presentation.pdf',
                Gr: '/download/presentations/Presentation.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/presentations/Presentation.ai',
                Ru: '/download/presentations/Presentation.ai',
                Fr: '/download/presentations/Presentation.ai',
                De: '/download/presentations/Presentation.ai',
                Gr: '/download/presentations/Presentation.ai',
            }
        }
    ],
    
    options: [1, 2, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: true,
    orderOrder: 3,
    createOrder: 3
  },
  {
    id: 4,
    productTypeId: 4,
    price: 250.00,
    product: 'badges',
    titleEn: 'Badge',
    titleRu: 'Бейдж',
    titleFr: 'Insigne',
    titleDe: 'Abzeichen',
    titleGr: 'Σήμα',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    
    iconSrcEn: require('@/assets/order/badge/Badge.jpg'),
    briefRu: `Элемент униформы, амуниции, в виде нагрудного знака, наклейки, карточки`,
    briefEn: `Element of uniform, ammunition, in the form of a badge, stickers, cards`,
    briefFr: `Un élément d'uniforme ou d'équipement, sous la forme d'un emblème de poitrine, d'autocollant ou de carte.`,
    briefDe: `Ein Element einer Uniform oder Ausrüstung in Form eines Brustemblems, Aufklebers oder einer Karte.`,
    briefGr: `Ένα στοιχείο στολής ή εξοπλισμού, σε μορφή σήματος στο στήθος, αυτοκόλλητου ή κάρτας.`,
    textEn: `Element of uniform, ammunition, in the form of a badge, sticker, card, 
            designed to provide information about its wearer`,
    textRu: `Элемент униформы, амуниции, в виде нагрудного знака, наклейки, карточки, 
            предназначенный для предоставления информации о его носителе`,
    textFr: `Un élément d'uniforme ou d'équipement, sous la forme d'un emblème de poitrine, d'autocollant ou de carte, conçu pour fournir des informations sur son porteur.`,
    textDe: `Ein Element einer Uniform oder Ausrüstung in Form eines Brustemblems, Aufklebers oder einer Karte, das Informationen über den Träger bereitstellt.`,
    textGr: `Ένα στοιχείο στολής ή εξοπλισμού, σε μορφή σήματος στο στήθος, αυτοκόλλητου ή κάρτας, σχεδιασμένο να παρέχει πληροφορίες για τον φορέα του.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/badge/Badge.pdf',
                Ru: '/download/badge/Badge.pdf',
                Fr: '/download/badge/Badge.pdf',
                De: '/download/badge/Badge.pdf',
                Gr: '/download/badge/Badge.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                rawEn: '/download/badge/Badge.ai',
                rawRu: '/download/badge/Badge.ai',
                rawFr: '/download/badge/Badge.ai',
                rawDe: '/download/badge/Badge.ai',
                rawGr: '/download/badge/Badge.ai',
            }
        }
    ],

    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 4,
    createOrder: 4
  },
  {
    id: 5,
    productTypeId: 5,
    price: 350.00,
    product: 'doorhangers',
    titleEn: 'Doorhanger',
    titleRu: 'Дорхенгеры',
    titleFr: 'Porte-affiches',
    titleDe: 'Türhänger',
    titleGr: 'Κρεμαστήρια πόρτας',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/doorhanger/Doorhanger.jpg'),
    briefRu: `Картонный крючок с рекламной информацией, которая вешается на ручку двери`,
    briefEn: `A door hanger is a plastic or cardboard sign, cut to hang from the handle or knob of a door`,
    briefFr: `Des crochets en carton avec des informations promotionnelles qui sont accrochés à une poignée de porte.`,
    briefDe: `Kartonhaken mit Werbeinformationen, die an einem Türgriff aufgehängt werden.`,
    briefGr: `Χάρτινα γάντζοι με προωθητικές πληροφορίες που κρέμονται σε μια λαβή πόρτας.`,
    textEn: `A door hanger is a plastic or cardboard sign, generally rectangular in shape, 
          cut to hang from the handle or knob of a door. They are sometimes used to 
          distribute print advertising to residences`,
    textRu: `Дорхенгер представляет собой табличку из пластика или картона, как правило, 
        прямоугольной формы. С вырезом,для подвеса на крючок или дверную ручку. 
        Иногда может использоваться для распространения печатной рекламы по жилым домам`,
    textFr: `Un porte-affiches est un panneau en plastique ou en carton, généralement de forme rectangulaire, avec une découpe pour être suspendu à un crochet ou à une poignée de porte. Il est parfois utilisé pour distribuer de la publicité imprimée dans les immeubles résidentiels.`,
    textDe: `Ein Türhänger ist ein Kunststoff- oder Kartonschild, in der Regel rechteckig, mit einer Aussparung zum Aufhängen an einem Haken oder Türgriff. Er wird manchmal zur Verteilung von gedruckter Werbung in Wohngebäuden verwendet.`,
    textGr: `Ένα κρεμαστήρι πόρτας είναι ένα πλαστικό ή χαρτόνινο πινακίδα, συνήθως ορθογώνιας μορφής, με μια ανοίγματος για να κρεμαστεί σε γάντζο ή λαβή πόρτας. Χρησιμοποιείται μερικές φορές για τη διανομή τυπωμένων διαφημίσεων σε κατοικημένα κτίρια.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/doorhanger/Doorhanger.pdf',
                Ru: '/download/doorhanger/Doorhanger.pdf',
                Fr: '/download/doorhanger/Doorhanger.pdf',
                De: '/download/doorhanger/Doorhanger.pdf',
                Gr: '/download/doorhanger/Doorhanger.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/badge/Badge.ai',
                Ru: '/download/badge/Badge.ai',
                Fr: '/download/badge/Badge.ai',
                De: '/download/badge/Badge.ai',
                Gr: '/download/badge/Badge.ai',
            }
        }
    ],
    isExample: true,

    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 5,
    createOrder: 5
  },
  {
    id: 6,
    productTypeId: 6,
    price: 40.00,
    product: 'brochures',
    titleEn: 'Brochure',
    titleRu: 'Брошюры',
    titleFr: 'Brochures',
    titleDe: 'Broschüren',
    titleGr: 'Φυλλάδια',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/brochure/brochure.jpg'),
    briefRu: `Непериодическое печатное издание объемом 4-48 страниц, состоящее из двух основных элементов: блока и обложки`,
    briefEn: `A non-periodical printed edition of 4-48 pages, consisting of two main 
            elements: a block and a cover`,
    briefFr: `Une publication imprimée non périodique composée de 4 à 48 pages, comprenant deux éléments principaux : le contenu intérieur et la couverture.`,
    briefDe: `Eine nicht-periodische gedruckte Publikation mit 4-48 Seiten, bestehend aus zwei Hauptkomponenten: dem Inhalt und dem Umschlag.`,
    briefGr: `Ένα μη περιοδικό εκτυπωμένο έντυπο αποτελούμενο από 4-48 σελίδες, αποτελούμενο από δύο κύρια στοιχεία: το εσωτερικό περιεχόμενο και το εξώφυλλο.`,
    textEn: `A non-periodical printed edition of 4-48 pages, consisting of two main 
          elements: a block and a cover, and fastened together with sewing, a paper clip, 
          thread or rope, and so on`,
    textRu: `Непериодическое печатное издание объемом 4-48 страниц, состоящее из двух 
          основных элементов: блока и обложки, и скрепленных между собой при помощи шитья, 
          скрепкой, ниткой или верёвкой и так далее`,
    textFr: `Une publication imprimée non périodique composée de 4 à 48 pages, comprenant deux éléments principaux : le contenu intérieur et la couverture, et reliée ensemble par couture, agrafage, fil, ficelle, etc.`,
    textDe: `Eine nicht-periodische gedruckte Publikation mit 4-48 Seiten, bestehend aus zwei Hauptkomponenten: dem Inhalt und dem Umschlag, und zusammengebunden durch Nähen, Heften, Faden, Schnur usw.`,
    textGr: `Ένα μη περιοδικό εκτυπωμένο έντυπο αποτελούμενο από 4-48 σελίδες, αποτελούμενο από δύο κύρια στοιχεία: το εσωτερικό περιεχόμενο και το εξώφυλλο, και συνδεδεμένα μεταξύ τους με ράψιμο, συρματοσυνδετήρα, νήμα, σχοινί κλπ.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/brochure/brochure.pdf',
                Ru: '/download/brochure/brochure.pdf',
                Fr: '/download/brochure/brochure.pdf',
                De: '/download/brochure/brochure.pdf',
                Gr: '/download/brochure/brochure.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/brochure/brochure.ai',
                Ru: '/download/brochure/brochure.ai',
                Fr: '/download/brochure/brochure.ai',
                De: '/download/brochure/brochure.ai',
                Gr: '/download/brochure/brochure.ai',
            }
        }
    ],

    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 6,
    createOrder: 6
  },
  {
    id: 7,
    productTypeId: 7,
    price: 50.00,
    product: 'calendars',
    titleEn: 'Calendar',
    titleRu: 'Календарь',
    titleFr: 'Calendrier',
    titleDe: 'Kalender',
    titleGr: 'Ημερολόγιο',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/calendar/calendar.jpg'),
    briefRu: `Незаменимый полиграфический сувенир для партнеров и клиентов, а также важный рабочий инструмент для менеджеров, кадровиков и бухгалтеров`,
    briefEn: `A periodical containing a sequential list of days, weeks, months of a given year`,
    briefFr: `Un souvenir imprimé indispensable pour les partenaires et les clients, ainsi qu'un outil important pour les managers, le personnel des ressources humaines et les comptables.`,
    briefDe: `Ein unverzichtbares gedrucktes Souvenir für Partner und Kunden sowie ein wichtiges Werkzeug für Manager, Personalverantwortliche und Buchhalter.`,
    briefGr: `Ένα ανεκτίμητο εκτυπωμένο αναμνηστικό για συνεργάτες και πελάτες, καθώς και ένα σημαντικό εργαλείο για διευθυντές, υπεύθυνους ανθρώπινου δυναμικού και λογιστές.`,
    textEn: `A periodical containing a sequential list of days, weeks, months of a given 
            year. An irreplaceable printing souvenir for partners and clients, as well as 
            an important working tool for managers, personnel officers and accountants`,
    textRu: `Периодическое издание, содержащее последовательный перечень дней, недель, месяцев данного года. 
            Незаменимый полиграфический сувенир для партнеров и клиентов, а также важный рабочий инструмент для менеджеров, кадровиков и бухгалтеров`,
    textFr: `Une publication périodique contenant une liste séquentielle des jours, semaines et mois d'une année donnée. Un souvenir imprimé indispensable pour les partenaires et les clients, ainsi qu'un outil important pour les managers, le personnel des ressources humaines et les comptables.`,
    textDe: `Eine periodische Veröffentlichung, die eine sequenzielle Auflistung der Tage, Wochen und Monate eines bestimmten Jahres enthält. Ein unverzichtbares gedrucktes Souvenir für Partner und Kunden sowie ein wichtiges Werkzeug für Manager, Personalverantwortliche und Buchhalter.`,
    textGr: `Μια περιοδική έκδοση που περιλαμβάνει μια συνεχή λίστα των ημερών, εβδομάδων και μηνών ενός συγκεκριμένου έτους. Ένα ανεκτίμητο εκτυπωμένο αναμνηστικό για συνεργάτες και πελάτες, καθώς και ένα σημαντικό εργαλείο για διευθυντές, υπεύθυνους ανθρώπινου δυναμικού και λογιστές.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/calendar/calendar.pdf',
                Ru: '/download/calendar/calendar.pdf',
                Fr: '/download/calendar/calendar.pdf',
                De: '/download/calendar/calendar.pdf',
                Gr: '/download/calendar/calendar.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/calendar/calendar.ai',
                Ru: '/download/calendar/calendar.ai',
                Fr: '/download/calendar/calendar.ai',
                De: '/download/calendar/calendar.ai',
                Gr: '/download/calendar/calendar.ai',
            }
        }
    ],

    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 7,
    createOrder: 7
  },
  {
    id: 8,
    productTypeId: 8,
    price: 59.00,
    product: 'vouchers',
    titleEn: 'Gift voucher',
    titleRu: 'Подарочный сертификат',
    titleFr: 'Carte cadeau',
    titleDe: 'Geschenkgutschein',
    titleGr: 'Πιστοποιητικό δώρου',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/gift-voucher/gift-voucher.jpg'),
    briefRu: `Обычно картонная или пластиковая карта, которая несет в себе некоторый эквивалент стоимости в валюте`,
    briefEn: `Usually a cardboard (certificate) or plastic card that carries some equivalent value in the currency`,
    briefFr: `Généralement une carte en carton ou en plastique qui représente une certaine valeur en devise.`,
    briefDe: `Normalerweise eine Karte aus Karton oder Kunststoff, die einen bestimmten Wert in Währung repräsentiert.`,
    briefGr: `Συνήθως μια κάρτα από χαρτόνι ή πλαστικό που αντιπροσωπεύει μια συγκεκριμένη αξία σε νόμισμα.`,
    textEn: `Usually a cardboard (certificate) or plastic card that carries some equivalent value in the currency accepted in the country of issue`,
    textRu: `Обычно картонная (сертификат) или пластиковая карта, которая несет в себе некоторый эквивалент стоимости в валюте, принятой в стране эмитента.`,
    textFr: `Une feuille de papier ou de plastique, une carte ou un document électronique indiquant un montant spécifique d'argent pouvant être échangé en magasin ou en ligne contre des biens de même valeur. Les bons  Les cartes cadeaux sont populaires en tant que prix et cadeaux.`,
    textDe: `Ein Blatt Papier oder Kunststoff, eine Karte oder ein elektronisches Dokument, auf dem ein bestimmter Geldbetrag angegeben ist, der in einem Geschäft oder online gegen Waren im gleichen Wert eingelöst werden kann. Geschenkgutscheine sind als Preise und Geschenke beliebt.`,
    textGr: `Ένα φύλλο χαρτί ή πλαστικού, μια κάρτα ή ένα ηλεκτρονικό έγγραφο που δηλώνει ένα συγκεκριμένο ποσό χρημάτων που μπορεί να ανταλλαγεί σε ένα κατάστημα ή online για αγαθά ίδιας αξίας. Τα πιστοποιητικά δώρων είναι δημοφιλή ως βραβεία και δώρα.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/gift-voucher/Gift_Certificate.pdf',
                Ru: '/download/gift-voucher/Gift_Certificate.pdf',
                Fr: '/download/gift-voucher/Gift_Certificate.pdf',
                De: '/download/gift-voucher/Gift_Certificate.pdf',
                Gr: '/download/gift-voucher/Gift_Certificate.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/gift-voucher/Gift_Certificate.ai',
                Ru: '/download/gift-voucher/Gift_Certificate.ai',
                Fr: '/download/gift-voucher/Gift_Certificate.ai',
                De: '/download/gift-voucher/Gift_Certificate.ai',
                Gr: '/download/gift-voucher/Gift_Certificate.ai',
            }
        }
    ],
    
    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 8,
    createOrder: 8
  },
  {
    id: 9,
    productTypeId: 9,
    price: 750.00,
    product: 'flyers',
    titleEn: 'Flyer',
    titleRu: 'Листовка',
    titleFr: 'Dépliant mozhet Prospectus',
    titleDe: 'Flyer',
    titleGr: 'Φυλλάδιο',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/listovka/listovka.jpg'),
    briefRu: `Издание в виде одного листа печатного материала, с одним сгибом или без сгибов`,
    briefEn: `Издание в виде одного листа печатного материала, с одним сгибом или без сгибов`,
    briefFr: `Un dépliant est une petite brochure informative. Il est principalement utilisé comme support promotionnel dans différentes campagnes publicitaires et actions de marketing.`,
    briefDe: `Ein Flyer ist ein kleiner informativer Handzettel. Er wird hauptsächlich als Werbematerial in verschiedenen Werbe- und Marketingkampagnen verwendet.`,
    briefGr: `Ένα φυλλάδιο είναι ένα μικρό ενημερωτικό φυλλάριο. Χρησιμοποιείται κυρίως ως προωθητικό υλικό σε διάφορες διαφημιστικές και προωθητικές εκστρατείες.`,
    textEn: 'Flyer text',
    textRu: `Издание в виде одного листа печатного материала, с одним сгибом или без сгибов.`,
    textFr: `Les dépliants sont souvent utilisés comme des laissez-passer gratuits, des invitations, voire des billets d'entrée pour des événements. Contrairement aux invitations, qui sont généralement personnalisées, n'importe qui peut utiliser un dépliant.`,
    textDe: `Flyer werden oft als kostenlose Eintrittskarten, Einladungen oder sogar Zugangsberechtigungen für Veranstaltungen verwendet. Im Gegensatz zu Einladungen, die normalerweise personalisiert sind, kann jeder einen Flyer nutzen.`,
    textGr: `Τα φυλλάδια χρησιμοποιούνται συχνά ως δωρεάν εισιτήρια, προσκλήσεις ή ακόμη και εισιτήρια εισόδου για κάποια εκδήλωση. Σε αντίθεση με τις προσκλήσεις, που συνήθως είναι εξατομικευμένες, οποιοσδήποτε μπορεί να χρησιμοποιήσει ένα φυλλάδιο.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/listovka/listovka.pdf',
                Ru: '/download/listovka/listovka.pdf',
                Fr: '/download/listovka/listovka.pdf',
                De: '/download/listovka/listovka.pdf',
                Gr: '/download/listovka/listovka.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/listovka/listovka.ai',
                Ru: '/download/listovka/listovka.ai',
                Fr: '/download/listovka/listovka.ai',
                De: '/download/listovka/listovka.ai',
                Gr: '/download/listovka/listovka.ai',
            }
        }
    ],
    
    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 9,
    createOrder: 9
  },
  {
    id: 10,
    productTypeId: 10,
    price: 99.99,
    product: 'tags-and-labels',
    titleEn: 'Tag and label',
    titleRu: 'Стикер и бирка',
    titleFr: 'Autocollant',
    titleDe: 'Aufkleber',
    titleGr: 'Αυτοκόλλητο',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/tag-label-sticker/tag-label-sticker.jpg'),
    briefRu: `Графический или текстовый знак, нанесённый в виде наклейки, бирки или талона на товар, экспонат, любой другой продукт производства`,
    briefEn: `A graphic or text sign applied in the form of a sticker, tag or coupon on a product, exhibit, any other product of production`,
    briefFr: `Un autocollant est un signe graphique ou textuel apposé sous forme d'étiquette adhésive ou de tag sur un produit, un exposant ou tout autre article manufacturé.`,
    briefDe: `Ein Aufkleber ist ein grafisches oder textliches Zeichen, das in Form eines Aufklebeetiketts oder Anhängers auf einem Produkt, einer Ausstellung oder einem anderen hergestellten Artikel angebracht wird.`,
    briefGr: `Ένα αυτοκόλλητο είναι ένα γραφικό ή κειμενικό σήμα που εφαρμόζεται ως αυτοκόλλητη ετικέτα ή επιγραφή σε ένα προϊόν, έκθεμα ή οποιοδήποτε άλλο κατασκευασμένο αντικείμενο.`,
    textEn: `A graphic or text sign applied in the form of a sticker, tag or coupon on a product, exhibit, any other product of production, indicating the manufacturer's trademark, name, production date, expiration date, and so on`,
    textRu: `Графический или текстовый знак, нанесённый в виде наклейки, бирки или талона на товар, экспонат, любой другой продукт производства, с указанием торговой марки производителя, названия, даты производства, срока годности и так далее`,
    textFr: `Il comprend généralement la marque du fabricant, le nom du produit, la date de fabrication, la date de péremption et d'autres informations pertinentes.`,
    textDe: `Er enthält in der Regel die Handelsmarke des Herstellers, den Produktnamen, das Herstellungsdatum, das Verfallsdatum und andere relevante Informationen.`,
    textGr: `Συνήθως περιλαμβάνει το εμπορικό σήμα του κατασκευαστή, το όνομα του προϊόντος, την ημερομηνία παραγωγής, την ημερομηνία λήξης και άλλες σχετικές πληροφορίες.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/tag-label-sticker/Sticker.pdf',
                Ru: '/download/tag-label-sticker/Sticker.pdf',
                Fr: '/download/tag-label-sticker/Sticker.pdf',
                De: '/download/tag-label-sticker/Sticker.pdf',
                Gr: '/download/tag-label-sticker/Sticker.pdf',
            }
        },
        {
            name: 'ai',
            files: {
                En: '/download/tag-label-sticker/Sticker.ai',
                Ru: '/download/tag-label-sticker/Sticker.ai',
                Fr: '/download/tag-label-sticker/Sticker.ai',
                De: '/download/tag-label-sticker/Sticker.ai',
                Gr: '/download/tag-label-sticker/Sticker.ai',
            }
        }
    ],

    options: [1, 3, 4, 5, 6, 7],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 10,
    createOrder: 10
  },
  {
    id: 11,
    productTypeId: 11,
    price: 77.00,
    product: 'leaflet',
    titleEn: 'Leaflet',
    titleRu: 'Лифлет',
    titleFr: 'Dépliant',
    titleDe: 'Faltblatt',
    titleGr: '???',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/leaflet/leaflet.jpg'),
    briefRu: `Издание в виде одного листа печатного материала, как правило с одним или несколькими сгибами`,
    briefEn: `A double-sided printed sheet, usually with two or three folds, folded in various ways`,
    briefFr: `Un dépliant est une publication sous la forme d'une seule feuille de matériau imprimé, généralement avec un ou plusieurs plis.`,
    briefDe: `Ein Faltblatt ist eine Veröffentlichung in Form eines einzelnen Blattes Druckmaterial, in der Regel mit einem oder mehreren Falzen.`,
    briefGr: `Ένα φυλλάδιο είναι μια έκδοση στη μορφή ενός μονού φύλλου εκτυπωμένου υλικού, συνήθως με έναν ή περισσότερους σγραμμούς.`,
    textEn: `A double-sided printed sheet, usually with two or three folds, folded in various 
        ways, for example: in half, accordion, delta or in any other way. One of the main signs of 
        a leaflet is the absence of any fastening elements: springs, glue, paper clips. 
        In the form of a leaflet, short guides, advertising brochures, programs of public 
        events, etc. are published.`,
    textRu: `Двусторонний отпечатанный лист, как правило, с двумя или тремя фальцами, 
        сложенный всевозможными способами, например: пополам, гармошкой, дельтообразно или 
        любым другим способом. Один из основных признаков лифлета — отсутствие каких-либо 
        скрепляющих элементов: пружины, клея, скрепок. В форме лифлета издаются краткие 
        путеводители, рекламные проспекты, программы публичных мероприятий и т. п.`,
    textFr: `C'est une feuille imprimée recto-verso, généralement avec deux ou trois plis, pliée de différentes manières telles que le pli en deux, le pli accordéon, le pli en portefeuille, ou toute autre méthode de pliage. L'un des principaux caractéristiques d'un dépliant est l'absence d'éléments de reliure tels que des spirales, de la colle ou des agrafes. Les dépliants sont utilisés pour des guides succincts, des brochures promotionnelles, des programmes d'événements, et plus encore.`,
    textDe: `Es handelt sich um ein beidseitig bedrucktes Blatt, in der Regel mit zwei oder drei Falzen, das auf verschiedene Weise gefaltet wird, wie zum Beispiel der Wickelfalz, dem Leporellofalz, dem Zickzackfalz oder einer anderen Faltmethode. Ein wesentliches Merkmal eines Faltblatts ist das Fehlen von Bindeelementen wie Spiralen, Klebstoff oder Heftklammern. Faltblätter werden für kurze Führer, Werbebroschüren, Veranstaltungsprogramme und mehr verwendet.`,
    textGr: `Είναι ένα διπλής όψης εκτυπωμένο φύλλο, συνήθως με δύο ή τρεις σγραμμούς, που διπλώνεται με διάφορους τρόπους, όπως το μισό δίπλωμα, το ακορντεόν δίπλωμα, το τρίγωνο δίπλωμα ή οποιαδήποτε άλλη μέθοδος διπλώματος. Ένα από τα κύρια χαρακτηριστικά ενός φυλλαδίου είναι η απουσία οποιωνδήποτε στοιχείων δέσιμου, όπως πηνία, κόλλα ή συνδετήρες. Τα φυλλάδια χρησιμοποιούνται για συνοπτικούς οδηγούς, διαφημιστικά φυλλάδια, προγράμματα εκδηλώσεων και άλλα.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/leaflet/leaflet.pdf',
                Ru: '/download/leaflet/leaflet.pdf',
                Fr: '/download/leaflet/leaflet.pdf',
                De: '/download/leaflet/leaflet.pdf',
                Gr: '/download/leaflet/leaflet.pdf',
            }
        },
        {
            name: 'psd',
            files: {
                En: '/download/leaflet/leaflet.psd',
                Ru: '/download/leaflet/leaflet.psd',
                Fr: '/download/leaflet/leaflet.psd',
                De: '/download/leaflet/leaflet.psd',
                Gr: '/download/leaflet/leaflet.psd',
            }
        }
    ],
    
    options: [1, 2, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 11,
    createOrder: 11
  },
  {
    id: 12,
    productTypeId: 12,
    price: 101.00,
    product: 'advertising',
    titleEn: 'Outdoor advertising',
    titleRu: 'Наружная реклама',
    titleFr: 'Publicité extérieure',
    titleDe: 'Außenwerbung',
    titleGr: 'Εξωτερική διαφήμιση',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/shtender/Shtender.jpg'),
    briefRu: `Рекламные щиты, суперсайты, брандмауэры, панели-кронштейны, указатели, стеллы, штендеры и наружные баннеры`,
    briefEn: `Billboards, supersites, firewalls, panel-brackets, pointer, stellas, pavement signs and outdoor banners`,
    briefFr: `Panneaux publicitaires, super sites, murs de marque, supports de panneaux, enseignes, stèles, chevalets et bannières extérieures`,
    briefDe: `Plakatwände, Super-Sites, Brandmauern, Plattenhalterungen, Schilder, Stelen, Aufsteller und Außenbanner`,
    briefGr: `Πινακίδες διαφήμισης, υπερστάσεις, τοίχοι με επωνυμία, τράβερσες πάνελ, πινακίδες, στήλες, επιγραφές και εξωτερικά πανό`,
    textEn: `Billboard. The most common form is in the form of a large shield 6x3 m. It is placed along roads and highways, usually has a backlight.
          City format. Luminous box 1.2 x 1.8 m. Also installed along city roads.
          City board. Same as format but twice the size of 2.7m x 3.7m.
          Supersite - a three-sided structure on a high support measuring 15 x 5 m, 12 x 3 m, 12 x 4 m. Usually installed near large stores.
          Firewall - a billboard with advertising on the walls of buildings. Sizes vary, from small to large, depending on the area.
          Panel-bracket - a medium-sized box illuminated from the inside, attached to the wall.
          Pointer. Advertising placed on road signs
          Stella - an advertising structure to indicate the location, for example, a cafe, a canteen, etc. Placed along highways, sometimes near the entrance to the office.
          Pavement sign is a portable outdoor advertising structure, which is installed on the street in close proximity to the advertiser.
          Outdoor banners`,
    textRu: `Рекламный щит. Наиболее распространена форма в виде большого щита 6х3 м. Размещается вдоль дорог и автомагистралей, обычно имеет подсветку.
        Городской формат. Светящийся бокс 1,2 х 1,8 м. Также устанавливаются вдоль городских дорог.
        Городской билборд. Такой же формат, но в два раза больше 2,7 м х 3,7 м.
        Суперсайт – трехсторонняя конструкция на высокой опоре размерами 15 х 5 м, 12 х 3 м, 12 х 4 м. Обычно устанавливаются возле крупных магазинов.
        Брандмауэр — рекламный щит с рекламой на стенах зданий. Широкий диапазон размеров, в зависимости от площади.
        Панель-кронштейн – подсвеченный изнутри короб среднего размера, прикрепленный к стене.
        Указатель. Размещение рекламы на дорожных знаках.
        Стелла - рекламная конструкция для обозначения местоположения, например, кафе, столовой и т. д. Размещаются вдоль магистралей, иногда возле входа в офис.
        Штендер представляет собой переносную наружную рекламную конструкцию, которая устанавливается на улице в непосредственной близости от рекламодателя.
        Наружные баннеры`,
    textFr: `Panneau publicitaire. La forme la plus courante est un grand panneau de 6x3m. Il est placé le long des routes et autoroutes et est généralement illuminé.
        Format urbain. Boîtier lumineux de 1,2x1,8m. Également installé le long des routes urbaines.
        Panneau urbain. Même format, mais deux fois plus grand, 2,7x3,7m.
        Supersite - une structure à trois faces sur un support élevé, mesurant 15x5m, 12x3m, 12x4m. Généralement installé près des grands magasins.
        Brandmauer - panneau publicitaire avec des publicités sur les murs des bâtiments. Large gamme de tailles en fonction de la surface.
        Support de panneau - boîte de taille moyenne éclairée de l'intérieur fixée à un mur.
        Panneau indicateur - placement de publicités sur les panneaux de signalisation routière.
        Stèle - structure publicitaire utilisée pour indiquer l'emplacement, comme les cafés, les cantines, etc. Placée le long des autoroutes, parfois près des entrées de bureau.
        Chevalet - structure publicitaire extérieure portable installée dans la rue à proximité immédiate de l'annonceur.
        Bannières extérieures`,
    textDe: `Werbetafel. Die gängigste Form ist ein großes 6x3m Schild. Es wird entlang von Straßen und Autobahnen platziert und ist in der Regel beleuchtet.
        Stadtausführung. Beleuchteter Kasten mit den Maßen 1,2x1,8m. Wird auch entlang von Stadtrouten installiert.
        Stadtplakat. Gleiche Größe, aber doppelt so groß, 2,7x3,7m.
        Supersite - eine dreiseitige Konstruktion auf hohem Träger mit den Maßen 15x5m, 12x3m, 12x4m. Wird normalerweise in der Nähe großer Geschäfte aufgestellt.
        Brandwand - Werbetafel mit Werbung an den Wänden von Gebäuden. Großer Größenbereich je nach Fläche.
        Plattenhalterung - innen beleuchteter mittelgroßer Kasten an einer Wand befestigt.
        Schild - Platzierung von Werbung auf Verkehrsschildern.
        Stele - Werbekonstruktion zur Kennzeichnung des Standorts, z. B. von Cafés, Kantinen usw. Wird entlang von Autobahnen und manchmal in der Nähe von Büroeingängen platziert.
        Aufsteller - eine tragbare Außenwerbekonstruktion, die in unmittelbarer Nähe des Werbetreibenden auf der Straße aufgestellt wird.
        Außenbanner`,
    textGr: `Πινακίδα διαφήμισης. Η πιο κοινή μορφή είναι ένα μεγάλο πινακίδα 6x3μ. Τοποθετείται κατά μήκος των δρόμων και αυτοκινητοδρόμων και συνήθως έχει φωτισμό.
        Αστική μορφή. Φωτιζόμενο κουτί με διαστάσεις 1,2x1,8μ. Επίσης τοποθετείται κατά μήκος των αστικών δρόμων.
        Αστική πινακίδα. Ίδιες διαστάσεις, αλλά διπλάσιο μέγεθος, 2,7x3,7μ.
        Supersite - τριών πλευρών κατασκευή σε υψηλή στήριξη με διαστάσεις 15x5μ, 12x3μ, 12x4μ. Συνήθως τοποθετούνται κοντά σε μεγάλα καταστήματα.
        Τοίχος με επωνυμία - πινακίδα με διαφήμιση στους τοίχους κτιρίων. Ευρεία γκάμα μεγεθών ανάλογα με την έκταση.
        Πλαίσιο-στήριγμα - εσωτερικά φωτιζόμενο μεσαίου μεγέθους κουτί που προσαρμόζεται σε τοίχο.
        Πινακίδα κατεύθυνσης - τοποθέτηση διαφημίσεων σε οδικές σηματοδοτήσεις.
        Στήλη - διαφημιστική κατασκευή που χρησιμοποιείται για να υποδείξει τη θέση, όπως καφέ, τραπεζαρία κ.λπ. Τοποθετούνται κατά μήκος των αυτοκινητοδρόμων, μερικές φορές κοντά στην είσοδο του γραφείου.
        Πινακίδα - φορητή διαφημιστική κατασκευή που τοποθετείται στον δρόμο κοντά στον διαφημιζόμενο.
        Εξωτερικά πανό`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/shtender/Stand.pdf',
                Ru: '/download/shtender/Stand.pdf',
                Fr: '/download/shtender/Stand.pdf',
                De: '/download/shtender/Stand.pdf',
                Gr: '/download/shtender/Stand.pdf',
            }
        },
        {
            name: 'cdr',
            files: {
                En: '/download/shtender/Stand.cdr',
                Ru: '/download/shtender/Stand.cdr',
                Fr: '/download/shtender/Stand.cdr',
                De: '/download/shtender/Stand.cdr',
                Gr: '/download/shtender/Stand.cdr',
            }
        }
    ],

    options: [1, 2, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 12,
    createOrder: 12
  },
  {
    id: 13,
    productTypeId: 13,
    price: 101.00,
    product: 'Press wall',
    titleEn: 'Press wall',
    titleRu: 'Бренд-волл',
    titleFr: 'Mur de marque',
    titleDe: 'Markenwand',
    titleGr: 'Τοίχος εταιρικής ταυτότητας',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/presswall/presswall.jpg'),
    briefRu: `Натянутый на каркас баннер для проведения мероприятий и презентаций`,
    briefEn: `A banner stretched over a frame, which is designed to decorate the interior during an event`,
    briefFr: `Bannière tendue pour événements et présentations`,
    briefDe: `Gespannter Banner für Veranstaltungen und Präsentationen`,
    briefGr: `Τεντωμένο πανό για εκδηλώσεις και παρουσιάσεις `,
    textEn: `A banner stretched over a frame, which is designed to decorate the interior 
            during an event (press conference, exhibition, presentation, show, ceremony, 
            and so on). The information on such a banner has an advertising, presentational
            or simply informative character`,
    textRu: `Баннер, натянутый на каркас, который предназначен для оформления интерьера 
            при проведении какого-либо события (пресс-конференции, выставки, презентации,
            показа, церемонии и так далее). Информация на таком баннере имеет рекламный, 
            презентационный или просто информативный характер`,
    textFr: `Bannière tendue sur cadre pour la décoration intérieure lors d'événements (conférences de presse, expositions, présentations, spectacles, cérémonies, etc.). Les informations sur une telle bannière ont un caractère promotionnel, présentationnel ou simplement informatif.`,
    textDe: `Auf Rahmen gespannter Banner zur Innenraumgestaltung bei Veranstaltungen (Pressekonferenzen, Ausstellungen, Präsentationen, Shows, Zeremonien usw.). Die Informationen auf einem solchen Banner haben einen werblichen, präsentativen oder einfach informativen Charakter.`,
    textGr: `Πανό τεντωμένο σε σκελετό για τη διακόσμηση του εσωτερικού χώρου κατά τη διάρκεια εκδηλώσεων (συνεντεύξεις τύπου, εκθέσεις, παρουσιάσεις, παραστάσεις, τελετές κλπ.). Οι πληροφορίες σε ένα τέτοιο πανό έχουν διαφημιστικό, παρουσιαστικό ή απλά ενημερωτικό χαρακτήρα`,

    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/presswall/presswall.pdf',
                Ru: '/download/presswall/presswall.pdf',
                Fr: '/download/presswall/presswall.pdf',
                De: '/download/presswall/presswall.pdf',
                Gr: '/download/presswall/presswall.pdf',
            }
        },
        {
            name: 'cdr',
            files: {
                En: '/download/presswall/presswall.cdr',
                Ru: '/download/presswall/presswall.cdr',
                Fr: '/download/presswall/presswall.cdr',
                De: '/download/presswall/presswall.cdr',
                Gr: '/download/presswall/presswall.cdr',
            }
        }
    ],

    options: [1, 2, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 13,
    createOrder: 13
  },
  {
    id: 14,
    productTypeId: 14,
    price: 10.00,
    product: 'Souvenir products',
    titleEn: 'Souvenir products',
    titleRu: 'Сувенирная продукция',
    titleFr: 'Produits souvenirs',
    titleDe: ' Souvenirartikel',
    titleGr: 'Προϊόντα αναμνηστικά ',
    icon: 'mdi-id-card',
    color: 'orange darken-2',
    iconSrcEn: require('@/assets/order/souvenirs/Souvenirs.jpg'),
    briefRu: `Блокноты, кружки, ручки и карандаши с печатью, ежедневники с нанесением, 
          флажки, флаги, значки, магниты, футболки, пакеты`,
    briefEn: `Notebooks, mugs, printed pens and pencils, diaries with drawing, flags, badges, 
        magnets, T-shirts, packages`,
    briefFr: `Carnets, tasses, stylos et crayons avec impression, agendas personnalisés, drapeaux, badges, aimants, t-shirts, sacs`,
    briefDe: `Notizbücher, Tassen, Stifte und Bleistifte mit Druck, personalisierte Tagebücher, Flaggen, Abzeichen, Magnete, T-Shirts, Taschen`,
    briefGr: `Σημειωματάρια, κούπες, στυλό και μολύβια με εκτύπωση, εξατομικευμένα ημερολόγια, σημαίες, σήματα, μαγνήτες, μπλουζάκια, τσάντες`,
    textEn: `Promotional products are a type of products used as gifts, prizes, handouts in 
        advertising campaigns, as well as corporate and business souvenirs, most often with a 
        logo or corporate identity of the customer on the product itself or its packaging. 
        Often in a similar context, the terms promotional gifts, promotional gifts, business 
        gifts are used. Promotional products are one of the marketing tools.`,
    textRu: `Рекламно-сувенирная продукция — это вид изделий, используемых в качестве подарков, 
        призов, раздаточных материалов в рекламных кампаниях, а также в виде корпоративных и 
        бизнес-сувениров, чаще всего с нанесением логотипа или фирменного стиля заказчика на 
        самом продукте или его упаковке. Часто в аналогичном контексте употребляются термины 
        промоподарки, промосувениры, бизнес-подарки. Промотовары являются одним из инструментов 
        маркетинга.`,
    textFr: `Les articles promotionnels sont des produits utilisés comme cadeaux, prix, supports publicitaires dans le cadre de campagnes publicitaires, ainsi que des souvenirs d'entreprise, généralement avec le logo ou l'identité d'entreprise du client apposé sur le produit ou son emballage. Des termes similaires utilisés dans ce contexte sont cadeaux promotionnels, souvenirs promotionnels, cadeaux d'affaires. Les articles promotionnels sont l'un des outils de marketing.`,
    textDe: `Werbeartikel sind eine Art von Produkten, die als Geschenke, Preise, Werbemittel in Werbekampagnen sowie als Firmen- und Geschäftssouvenirs verwendet werden, oft mit dem Logo oder der Corporate Identity des Kunden auf dem Produkt oder seiner Verpackung. Ähnliche Begriffe, die in diesem Zusammenhang verwendet werden, sind Werbegeschenke, Promo-Souvenirs, Businessgeschenke. Werbeartikel sind ein Marketinginstrument.`,
    textGr: `Τα προωθητικά είδη είναι μια κατηγορία προϊόντων που χρησιμοποιούνται ως δώρα, βραβεία, διανομή υλικού σε διαφημιστικές εκστρατείες, καθώς και ως εταιρικά και επαγγελματικά σουβενίρ, συχνά με το λογότυπο ή την εταιρική ταυτότητα του πελάτη που εφαρμόζεται στο προϊόν ή τη συσκευασία του. Σε αυτό το πλαίσιο χρησιμοποιούνται συχνά και οι όροι προωθητικά δώρα, προωθητικά σουβενίρ, επαγγελματικά δώρα. Τα προωθητικά είδη αποτελούν ένα από τα εργαλεία του μάρκετινγκ.`,
    
    format: [
        {
            name: 'pdf',
            files: {
                En: '/download/souvenirs/Souvenirs.pdf',
                Ru: '/download/souvenirs/Souvenirs.pdf',
                Fr: '/download/souvenirs/Souvenirs.pdf',
                De: '/download/souvenirs/Souvenirs.pdf',
                Gr: '/download/souvenirs/Souvenirs.pdf',
            }
        },
        {
            name: 'cdr',
            files: {
                En: '/download/souvenirs/Souvenirs.cdr',
                Ru: '/download/souvenirs/Souvenirs.cdr',
                Fr: '/download/souvenirs/Souvenirs.cdr',
                De: '/download/souvenirs/Souvenirs.cdr',
                Gr: '/download/souvenirs/Souvenirs.cdr',
            }
        }
    ],

    options: [1, 2, 3, 4, 5, 6, 7, 8],
    availableOrder: true,
    availableCreate: false,
    orderOrder: 14,
    createOrder: 14
  },
]

const options = [{
    id: 1,
    name: 'size',
    titleEn: 'Size',
    titleRu: 'Размер',
    type: 'radiobutton',
    required: 0
  },
  {
    id: 2,
    name: 'page',
    titleEn: 'Pages',
    titleRu: 'Количество страниц',
    type: 'radiobutton',
    required: 0
  },
  {
    id: 3,
    name: 'corners',
    titleEn: 'Corners',
    titleRu: 'Углы',
    type: 'radiobutton',
    required: 0
  },
  {
    id: 4,
    name: 'orientation',
    titleEn: 'Orientation',
    titleRu: 'Ориентация',
    type: 'radiobutton',
    required: 0
  },
  {
    id: 5,
    name: 'style',
    titleEn: 'Style',
    titleRu: 'Стили',
    type: 'checkboxes',
    required: 0
  },
  {
    id: 6,
    name: 'colour',
    titleEn: 'Colour',
    titleRu: 'Цвета',
    type: 'checkboxes',
    required: 0
  },
  {
    id: 7,
    name: 'logo',
    titleEn: 'Logo/photo area',
    titleRu: 'Логотип или фото',
    type: 'checkbox',
    required: 0
  },
  {
    id: 8,
    name: 'premium',
    titleEn: 'Premium',
    titleRu: 'Премиум',
    type: 'checkbox',
    required: 0,
    hideForOrder: true
  },
]

export {
  products,
  options,
}
