import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueRouter from 'vue-router';

import store from './store';
import vuetify from './plugins/vuetify';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: '6LftclEpAAAAABqNQ0_-DUohVnhI6HsrFjirfsSH',   loaderOptions: {
	useRecaptchaNet: true,
	autoHideBadge: true
}})

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

Vue.config.productionTip = false;

Vue.use(VueRouter);

import './styles/styles.scss';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
const options = {
    timeout: 100000,
    pauseOnHover: true,
    closeOnClick: false,
    position: 'top-right',

    duration: 300000,
};
Vue.use(VueToast, options);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
