export class Contacts {
    static EmailEn = 'info@elkpixel.com';
    static EmailRu = 'info@elkpixel.com';
    static EmailFr = 'info@elkpixel.com';
    static EmailDe = 'info@elkpixel.com';
    static EmailGr = 'info@elkpixel.com';

    static PhoneNumberEn = '+123-456-789';
    static PhoneNumberRu = '+123-456-789';
    static PhoneNumberFr = '+123-456-789';
    static PhoneNumberDe = '+123-456-789';
    static PhoneNumberGr = '+123-456-789';

    static AddressEn = 'ElkByte LTD Dositheou 7, 301 1071 Nicosia, Cyprus';
    static AddressRu = 'ElkByte LTD Dositheou 7, 301 1071 Nicosia, Cyprus';
    static AddressFr = 'ElkByte LTD Dositheou 7, 301 1071 Nicosia, Cyprus';
    static AddressDe = 'ElkByte LTD Dositheou 7, 301 1071 Nicosia, Cyprus';
    static AddressGr = 'ElkByte LTD Dositheou 7, 301 1071 Nicosia, Cyprus';
}