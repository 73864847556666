<template>
  
    <div class="top">
        <nav class="navbar mx-auto">
            <div class="container-fluid d-flex justify-space-between align-center px-4">
                <a class="navbar-brand logo" href="/">
                    <img src="@/assets/logo.svg" 
                        :alt="ProjectName.NameEng" :width="isMobile? '200' : '300'" height="60" class="">
                        <!-- {{ ProjectName.NameEng }} -->
                </a>

                <div class="d-flex flex-row justify-space-between">
                    <!-- <Auth /> -->
                    <LanguageSelect />
                    <!-- <Cart /> -->
                </div>
            </div>
        </nav>
    </div>

</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import LanguageSelect from '@/components/LanguageSelect.vue';
  import Cart from '@/components/Cart.vue';
  import Auth from '@/components/auth/Auth.vue';
  import { ProjectName } from '@/constants/project-name.js';

  export default {
    name: 'TopMenu',
    components: {
      LanguageSelect,
      Cart,
      Auth
    },
    data: () => ({
      collapseOnScroll: true,
      ProjectName
    }),
    computed: {
      ...mapGetters(["currentLanguageText", "currentLanguageIcon"]),
      ...mapState({
        currentLanguage: (state) => state.language.currentLanguage,
        languages: (state) => state.language.languages
      }),

      isMobile() {
        return window.screen.width <=576;
      }
    },
    methods: {
      ...mapActions(["setCurrentLanguage"]),
      setLanguage(lang) {
        this.setCurrentLanguage({ language: lang });
      },
      goTo(path){
        this.$router.push(path)
      }
    },
  };
</script>

<style lang="scss" scoped>
@import '@/styles/_variables';
    .top {
        border-bottom: 1px solid $border-light !important;
    }
  .logo {
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .navbar {
    width: 100%;
    max-width: 1700px;
  }

  @media (max-width: 600px) {
    .logo {
      font-size: $font-size-base;
    }
  }
</style>

