<template>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            outlined
            :dark="dark"
            allow-overflow
            v-bind="attrs"
            v-on="on"
        >
            <v-avatar class="lang-menu-flag">
            <img
                class="d-block mr-md-2"
                :src="(currentLanguage == 'En' && isAmerica) ? IconUs : currentLanguageIcon"
                :alt="currentLanguage"
            >
            </v-avatar>
            <span v-if="$vuetify.breakpoint.mdAndUp">{{ currentLanguageText }}</span>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(lang, index) in languages"
          :key="index"
          @click="setLanguage(lang.value)"
        >
          <v-list-item-icon>
            <img :src="(lang.value == 'En' && isAmerica) ? IconUs : lang.iconSrc"
              :alt="lang.value"
              class="mr-2 d-block lang-menu-item-flag"
            />
          </v-list-item-icon>
          <v-list-item-title class="text-start">
            {{ lang.text }}
          </v-list-item-title>
          <v-list-item-action>

                <v-icon
                v-if="(lang.value === currentLanguage)"
                color="black"
                >mdi-check</v-icon>

            </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>

</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';

  export default {
    name: 'LanguageSelect',
    props: {
      dark: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      drawer: null,
      isAmerica: false
    }),
    mounted() {
        let locale = Intl.DateTimeFormat().resolvedOptions().locale;

        this.isAmerica = locale == "en-US";
    },
    computed: {
      ...mapGetters(["currentLanguageText", "currentLanguageIcon" , "IconUs"]),
      ...mapState({
        currentLanguage: (state) => state.language.currentLanguage,
        languages: (state) => state.language.languages
      }),
    },
    methods: {
      ...mapActions(["setCurrentLanguage"]),
      setLanguage(lang) {
        this.setCurrentLanguage({ language: lang });
        this.drawer = false
      },
     clickDrawer() {
      window.scrollTo(0,0);
      this.drawer = !this.drawer
     }
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/_variables';
  .lang-menu-flag {
    border-radius: 0 !important;
  }

  .lang-menu-flag,
  .lang-menu-item-flag {
    width: 35px !important;
    min-width: 35px !important;
    height: 30px !important;
  }
</style>
