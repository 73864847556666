import {
  products,
  options
} from '@/helper/products';

import {
  types as productTypes
} from '@/helper/base';

const state = {
  products: products,
  productsOrder: products
    .filter(p => p.availableOrder)
    .sort((a, b) => a.orderOrder > b.orderOrder ? 1 : -1),
  options: options,
  currentProduct: null,
  currentProductOptionIds: [],
  productTypes: productTypes,
  currentProductId: null,
  productTypeId: null,

  examples: products.filter((p) => p.isExample),
  currentExample: null
}

const getters = {
  currentProduct: state => products.find((p) => p.id === state.currentProductId),
  currentProductOptions: state => options.filter(o => state.currentProductOptionIds.includes(o
    .id)),
  productTypeId: state => state.productTypeId,
  currentExample: state => state.currentExample || state.examples[0]
}

const mutations = {
  getCurrentProduct: (state, payload) => {
    state.currentProduct = state.products.find(p => p.product === payload)
    state.currentProductOptionIds = state.products.find(p => p.product === payload).options

    state.currentProductId = state.products.find((p) => p.product === payload).id
    state.productTypeId = state.products.find((p) => p.product === payload).productTypeId
  },

  getCurrentExample: (state, payload) => {
    state.currentExample = state.examples.find((p) => p.id === payload)
  }
}

const actions = {
  selectProduct(context,
    name
  ) {
    return context.commit('getCurrentProduct', name)
  },

  selectExample(context, id) {
    return context.commit('getCurrentExample', id)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
