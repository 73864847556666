import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from 'vue-router'

Vue.use(VueRouter);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router);

const routes = [{
    path: '/',
    name: 'main',
    //component: MainPage,
    component: () => import( /* webpackChunkName: "main" */ '@/views/MainPage.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: () => import( /* webpackChunkName: "Privacy" */ '@/views/privacy/Privacy.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "Contact" */ '../views/Contact.vue'),
  },
  {
    path: '/order',
    name: 'Order',
    //component: TemplateBusinessCards,
    component: () => import( /* webpackChunkName: "Order" */
      '../views/order/Order.vue'),
  },
  {
    path: '/products/:name',
    name: 'OrderProduct',
    //component: TemplateBusinessCards,
    component: () => import( /* webpackChunkName: "about" */
      '../views/order/OrderProduct.vue'),
    props: true
  },
  {
    path: '/create-order',
    name: 'CreateOrder',
    //component: TemplateBusinessCards,
    component: () => import( /* webpackChunkName: "about" */
      '../views/order/CreateOrder.vue'),
    props: true
  },
  {
    path: '/order-product/:name/detail',
    name: 'orderDetail',
    //component: TemplateBusinessCards,
    component: () => import( /* webpackChunkName: "about" */
      '../views/templates/OrderDetail.vue'),
  },
  {
    path: '/create-product/:name',
    name: 'CreateProduct',
    //component: TemplateBusinessCards,
    component: () => import( /* webpackChunkName: "about" */
      '../views/templates/TemplateProducts.vue'),
  },
  {
    path: '/create-product/:name/:id',
    name: 'templateProduct',
    component: () => import( /* webpackChunkName: "about" */
      '../views/templates/TemplateProduct.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import( /* webpackChunkName: "about" */
      '../views/account/Account.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import( /* webpackChunkName: "about" */
      '../views/account/Cart.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import( /* webpackChunkName: "about" */
      '../views/account/Orders.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import( /* webpackChunkName: "about" */
      '../views/account/Payment.vue'),
  },
  {
    path: '/subscribe-form',
    name: 'SubscribeForm',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/subscribeForm.vue'),
  },
  {
    path: '/subscribe-check-email',
    name: 'SubscribeCheckEmail',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/subscribeCheckEmail.vue'),
    props: true
  },
  {
    path: '/unsubscribe-check-email',
    name: 'UnsubscribeCheckEmail',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/unsubscribeCheckEmail.vue'),
    props: true
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/success.vue'),
  },
  {
    path: '/success-unsubscription',
    name: 'SuccessUnsubscription',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/successUnsubscription.vue'),
  },
  {
    path: '/unsubscribe-form',
    name: 'UnsubscribeForm',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/unsubscribeForm.vue'),
  },
  {
    path: '/unsubscribing',
    name: 'Unsubscribe',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/unsubscribe.vue'),
  },
  {
    path: '/confirm-subscription',
    name: 'ConfirmSubscription',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/confirmSubscription.vue'),
  },
  {
    path: '/confirm-unsubscription',
    name: 'ConfirmUnsubscription',
    component: () => import( /* webpackChunkName: "about" */ '../views/subscribe/confirmUnsubscription.vue'),
  },
];

const router = new VueRouter({
		mode: 'history',
    routes,
		scrollBehavior (to, from, savedPosition) {
			window.scrollTo(0, 0)
			return { top: 0, behavior: 'smooth'}
		}
});

export default router;

