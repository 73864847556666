<template>
  <div class="footer">
    <div class="footer-content">
      <v-row>
        <v-col cols="12" sm="3" class="d-flex flex-column align-start">
            <h4 class="footer-links-title">
                <div v-if="currentLanguage === 'En'">Quick Links</div>
                <div v-if="currentLanguage === 'Ru'">Ссылки</div>
                <div v-if="currentLanguage === 'Fr'">Liens</div>
                <div v-if="currentLanguage === 'De'">Links</div>
                <div v-if="currentLanguage === 'Gr'">Σύνδεσμοι</div>
                
            </h4>
          <ul class="footer-links">
            <li v-for="(link, i) in links" :key="i">
                <router-link :to="{name: link.name}" class="footer-link">
                    <div>{{ link[`title${currentLanguage}`] }}</div>
                </router-link>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="5" class="d-flex flex-column align-start">
            <h4 class="footer-links-title">
                <div v-if="currentLanguage === 'En'">Headquarters</div>
                <div v-if="currentLanguage === 'Ru'">Главный офис</div>
                <div v-if="currentLanguage === 'Fr'">Quartier général</div>
                <div v-if="currentLanguage === 'De'">Hauptquartier</div>
                <div v-if="currentLanguage === 'Gr'">Αρχηγείο</div>             
            </h4>
            <ul class="footer-links">
                <li>
                    {{ Contacts[`Address${currentLanguage}`] }}
                </li>
                <br />
                <!-- <li>
                    Phone: {{ Contacts[`PhoneNumber${currentLanguage}`] }}
                </li> -->
                <li>
                    Email: {{ Contacts[`Email${currentLanguage}`] }}
                </li>
            </ul>
        </v-col>
        <!-- <v-col cols="12" sm="4" class="d-flex flex-column align-start">
            <h4 class="footer-links-title">
                {{ content.find(e => e.lang === currentLanguage).text }}           
            </h4>
            <v-form @submit.prevent="subscribe" class="mt-2">
                <v-text-field
                    v-model="email"
                    outlined dense rounded
                    placeholder="Email"
                    class="subscribe white--text"
                ></v-text-field>
                <p v-if="errors">{{errors}}</p>
                <v-btn type="submit" block rounded class="subscribe-btn">
                    {{ content.find(e => e.lang === currentLanguage).btn }}
                </v-btn>
            </v-form>

        </v-col> -->
      </v-row>
    </div>
    <div class="copyright">
      <div class="footer-content">
        <v-row>
          <v-col cols="6" sm="4" class="d-flex flex-row justify-start order-1">
            © {{(new Date).getFullYear()}} {{ ProjectName.NameEng }}
          </v-col>
          <v-col cols="12" sm="4" class="d-flex flex-row justify-center order-2">
            <v-img
              lazy-src="@/assets/footer/mastercard.svg"
              max-height="30"
              max-width="42"
              src="@/assets/footer/mastercard.svg"
              class="mx-3"
            ></v-img>
            <v-img
              lazy-src="@/assets/footer/visa.svg"
              max-height="30"
              max-width="42"
              src="@/assets/footer/visa.svg"
              class="mx-3"
            ></v-img>
            <v-img
              lazy-src="@/assets/footer/paypal.svg"
              max-height="30"
              max-width="42"
              src="@/assets/footer/paypal.svg"
              class="mx-3"
            ></v-img>
          </v-col>
          <v-col cols="6" sm="4" class="d-flex flex-row justify-end order-1 order-md-3">
            <LanguageSelect dark />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import LanguageSelect from '@/components/LanguageSelect.vue';
  import { ProjectName } from '@/constants/project-name.js';
  import { Contacts } from '@/constants/contacts.js';
  import { mailApiKey } from '@/constants/mailApiKey';

  export default {
    name: 'Footer',
    components: {
      LanguageSelect
    },
    data: () => ({
        ProjectName,
        Contacts,
        links: [
            {
                name: 'main',
                titleEn: 'Home',
                titleRu: 'Главная',
                titleFr: 'Accueil',
                titleDe: 'Startseite',
                titleGr: 'Αρχική σελίδα',
            },
            {
                name: 'Contact',
                titleEn: 'Contact',
                titleRu: 'Контакты',
                titleFr: 'Contacts',
                titleDe: 'Kontakte',
                titleGr: 'Επαφές',
            },
            {
                name: 'About',
                titleEn: 'About Us',
                titleRu: 'О нас',
                titleFr: 'À propos de nous',
                titleDe: 'Über uns',
                titleGr: 'Σχετικά με εμάς',
            },
            // {
            //     name: 'About',
            //     titleEn: 'How to make order',
            //     titleRu: 'Как сделать заказ',
            //     titleFr: 'Comment passer commande',
            //     titleDe: 'So geben Sie eine Bestellung auf',
            //     titleGr: 'Πώς να κάνετε παραγγελία',
            // },
            // {
            //     name: 'SubscribeForm',
            //     titleEn: 'Receive materials',
            //     titleRu: 'Получать материалы',
            //     titleFr: 'Recevoir des matériaux',
            //     titleDe: 'Materialien hinzufügen',
            //     titleGr: 'Λήψη υλικού',
            // },
            {
                name: 'Privacy',
                titleEn: 'Privacy Policy',
                titleRu: 'Политика конфиденциальности',
                titleFr: 'Politique de confidentialité',
                titleDe: 'Datenschutz-Bestimmungen',
                titleGr: 'Πολιτική Απορρήτου',
            }
        ],
        content: [
            {lang: 'En', btn: 'Subscribe', text: `Newsletter`},
            {lang: 'Ru', btn: 'Подписаться', text: `Новостная рассылка`},
            {lang: 'Fr', btn: `S'abonner`, text: `Bulletin`},
            {lang: 'De', btn: 'Abonnieren', text: `Newsletter`},
            {lang: 'Gr', btn: 'Εγγραφείτε', text: `Ενημερωτικό δελτίο`},
        ],
        email: null,
        errors: null,
        rulesEmail: [
            value => {
                if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;

                return 'Email is required'
            },
        ],
        loading: false,
    }),
    computed: {
      ...mapGetters(["currentLanguageText", "currentLanguageIcon"]),
      ...mapState({
        currentLanguage: (state) => state.language.currentLanguage,
        languages: (state) => state.language.languages
      }),
    },
    methods: {
      ...mapActions(["setCurrentLanguage"]),
      setLanguage(lang) {
        this.setCurrentLanguage({ language: lang });
      },

        subscribe(e) {
            this.errors = null;

            if (!this.email) {
                this.errors = 'Email is required';
            } else if (!(/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(this.email))) {
                this.errors = 'Valid email is required';
            }

            if (!this.errors) {
                console.log('subscribe', this.email);
                this.$router.push({name: 'SubscribeCheckEmail', params: { email: this.email}})
                this.email = null
            }

            e.preventDefault();
        }
    },
  };
</script>

<style lang="scss">
@import '@/styles/_variables';
  .footer {
    background-color: $body-color;
    color: $white;
    text-align: center;
    box-sizing: border-box;
    .copyright {
      background-color: $black;
      color: $white;
    }
    .footer-links-title {
      border-bottom: 1px solid $white;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
    .footer-links {
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: start;
      .footer-link {
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .footer-content {
    width: 80%;
    max-width: 1700px;
    margin: auto;
    padding: 20px 0;

    .subscribe {
        caret-color: white !important;

        &-btn {
            min-height: 40px !important;
            background-color: white !important;
            text-transform: none;
            color: black;
        }
    }

    .v-text-field {
        input {
            color: white !important;
        }
    }

    .v-input__slot fieldset {
        color: white !important;
    }

    .v-text-field__slot input::placeholder {
        color: white !important;
    }
  }
</style>
