<template>
  <v-app>
    <TopMenu />

    <v-main>     
      <router-view />
    </v-main>
    <Footer />

    <Cookies />
    
  </v-app>
</template>

<script>
import TopMenu from './components/TopMenu.vue';
import MainPage from '@/views/MainPage.vue';
import Footer from '@/components/Footer.vue';
import Cookies from '@/components/Cookies.vue'

export default {
  name: 'App',
  components: {
    TopMenu,
    MainPage,
    Footer,
    Cookies
  },

  data: () => ({
    //
  }),

};
</script>

<style lang="scss">
.v-navigation-drawer--temporary {
z-index: 999999 !important;
}
</style>
