<template>

  <div class="banner py-2 px-5">
    <div class="text-center">
        {{ text[currentLanguage] }}
        <router-link :to="{name: 'SubscribeForm'}" class="link">{{ btns[currentLanguage] }}</router-link>
    </div>
  </div>

</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import ButtonFlat from '@/components/buttons/ButtonFlat.vue';

  export default {
    name: 'Banner',
    components: {
      ButtonFlat
    },
    data: () => ({
        text: {
            En: 'Learn more and download free',
            Ru: 'Узнайте подробности и скачайте бесплатные',
            Fr: 'En savoir plus et télécharger des documents',
            De: 'Erfahren Sie mehr und laden Sie kostenlose Materialien ',
            Gr: 'μάθετε περισσότερα και κατεβάστε δωρεάν'
        },
        btns: {
            En: 'materials',
            Ru: 'материалы',
            Fr: 'gratuits',
            De: 'herunter',
            Gr: 'υλικό'
        }
    }),
    computed: {
      ...mapGetters(["currentLanguageText", "currentLanguageIcon"]),
      ...mapState({
        currentLanguage: (state) => state.language.currentLanguage,
      }),
    },
    methods: {
      goTo(link) {
        this.$router.push(link);
      }
    }
  }
</script>

<style lang="scss">
@import '@/styles/_variables';
  .v-banner__wrapper {
      border-bottom: none !important;

    .v-banner__actions {
      margin-left: 0;
    }
  }
  .link {
    color: $black !important;
    margin-right: auto;
    font-weight: $font-weight-semibold;

    &:hover {
      color: $palette-gray !important;
      text-decoration: underline;
    }
  }

  .banner {
    background-color: $card-lightblue;
  }
</style>