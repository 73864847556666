<template>

    <div id="cookie_note">
        <p>
            {{ content.find(e => e.lang === currentLanguage).text }}, 
            <a href="#" @click="goTo('Privacy')">{{ content.find(e => e.lang === currentLanguage).link }}</a>.
        </p>
        <button 
            class="button cookie_accept btn btn-primary btn-sm" 
            @click="setCookie('cookies_policy', 'true', 365)"
        >
            {{content.find(e => e.lang === currentLanguage).btn }}
        </button>
    </div>

  </template>
  
  <script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  
  export default {
    name: 'Cookies',
    components: {

    },
  
    data: () => ({
        content: [
            {lang: 'En', btn: 'I agree', text: `We use cookies to improve the performance of the site. By staying on our site, you agree to the terms of use of cookies. To read our Privacy and Cookie Policy, please `, link: `click here`},
            {lang: 'Ru', btn: 'Я согласен', text: `Мы используем файлы cookies для улучшения работы сайта. Оставаясь на нашем сайте, вы соглашаетесь с условиями использования файлов cookies. Чтобы ознакомиться с нашими Положениями о конфиденциальности и об использовании файлов cookie`, link: `нажмите здесь`},
            {lang: 'Fr', btn: `Je suis d'accord`, text: `Nous utilisons des cookies pour améliorer les performances du site. En restant sur notre site, vous acceptez les conditions d'utilisation des cookies. Pour lire notre politique de confidentialité et de cookies`, link: `cliquez ici`},
            {lang: 'De', btn: 'Ich bin einverstanden', text: `Wir verwenden Cookies, um die Leistung der Website zu verbessern. Indem Sie auf unserer Website bleiben, stimmen Sie den Nutzungsbedingungen für Cookies zu. Um unsere Datenschutz- und Cookie-Richtlinie zu lesen`, link: `klicken Sie hier`},
            {lang: 'Gr', btn: 'συμφωνώ', text: `Χρησιμοποιούμε cookies για να βελτιώσουμε την απόδοση του ιστότοπου. Παραμένοντας στον ιστότοπό μας, συμφωνείτε με τους όρους χρήσης των cookies. Για να διαβάσετε την Πολιτική Απορρήτου και Cookies`, link: `Κάντε κλικ ΕΔΩ`},
        ],
    }),

    computed: {
        ...mapGetters(["currentLanguageText", "currentLanguageIcon", "currentExample"]),
        ...mapState({
          currentLanguage: (state) => state.language.currentLanguage,
        }),
    },
  
    mounted() {
      this.checkCookies();
    },
  
    methods: {
      setCookie(name, value, days) {
          let expires = "";
          if (days) {
              let date = new Date();
              date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
              expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "") + expires + "; path=/";

          let cookieNote = document.getElementById('cookie_note');
          cookieNote.classList.remove('show');
      },
  
      getCookie(name) {
          let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
          return matches ? decodeURIComponent(matches[1]) : undefined;
      },
  
      checkCookies() {
          let cookieNote = document.getElementById('cookie_note');
  
          // Если куки cookies_policy нет или она просрочена, то показываем уведомление
          if (!this.getCookie('cookies_policy')) {
              cookieNote.classList.add('show');
          }
  
      },

      goTo(name) {
          this.$router.push({name: name})
        },
    }
  
  };
  </script>
  
  <style lang="scss">
    #cookie_note{
        display: none;
        position: fixed;
        bottom: 15px;
        left: 50%;
        max-width: 90%;
        transform: translateX(-50%);
        padding: 20px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
    }

    #cookie_note p{
        margin: 0;
        font-size: 0.7rem;
        text-align: left;
        color: black;
    }

    .cookie_accept{
        width:30%;
    }
    @media (min-width: 768px){
        #cookie_note.show{
            display: flex;
        }

        .cookie_accept{
            margin-left: 10px;
        }
    }

    @media (max-width: 767px){
        #cookie_note.show{
            display: flex;
            flex-direction: column;
        }

        .cookie_accept{
            margin: 15px auto 0;
        }
    }
  </style>
  