<template>
  <Wrapper>
   
      <v-row class="my-5 mx-5 mx-md-0">
        <v-col cols="12" sm="6" md="3"
          v-for="(product, i) in products" :key="i">
        <v-hover v-slot="{ hover, props }">
          <v-card
            class="button-class d-flex flex-column justify-center align-center"
            outlined
            height="200"
            v-bind="props"
            @click="clickProduct(product)"
          > 
            <v-img
              v-if="!hover"
              :lazy-src="product.iconSrc"
              max-height="133"
              max-width="160"
              :src="product.iconSrcEn"
            >
            </v-img>
            <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out v-card--reveal active-button-class order-sm-0 order-1"
              style="height: 100%; width: 100%;"         
            >
              <div v-if="currentLanguage === 'En'" class="active-button-class-text text-center">
                <p>{{ product.briefEn}}</p>
                <p class="mt-auto text-decoration-underline">Click to learn more</p>
              </div>
              <div v-if="currentLanguage === 'Ru'" class="active-button-class-text text-center">
                {{ product.briefRu}}
                <p class="mt-auto text-decoration-underline">Подробнее</p>
              </div>
              <div v-if="currentLanguage === 'Fr'" class="active-button-class-text text-center">
                {{ product.briefFr}}
                <p class="mt-auto text-decoration-underline">Cliquez pour en savoir plus</p>
              </div>
              <div v-if="currentLanguage === 'Gr'" class="active-button-class-text text-center">
                {{ product.briefGr}}
                <p class="mt-auto text-decoration-underline">Περισσότερο</p>
              </div>
              <div v-if="currentLanguage === 'De'" class="active-button-class-text text-center">
                {{ product.briefDe}}
                <p class="mt-auto text-decoration-underline">Klicke, um mehr zu lernen</p>
              </div>
            </div>
            <v-card-text class="text-center d-flex justify-center pa-0 mt-auto order-sm-1 order-0">
              <div class="button-class-title">{{ product[`title${currentLanguage}`] }}</div>
            </v-card-text>
          </v-card>
        </v-hover>
         
        </v-col>
      </v-row>
 
  </Wrapper>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import Wrapper from '@/components/Wrapper.vue';
  import ButtonRound from '@/components/buttons/ButtonRound.vue';

  export default {
    name: 'Order',
    components: {
      Wrapper,
      ButtonRound
    },
    data: () => ({
      carousel: null,
      sreenSize: window.innerWidth
    }),
    created() {
      window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
      window.removeEventListener("resize", this.myEventHandler);
    },
    computed: {
      ...mapGetters(["currentLanguageText", "currentLanguageIcon"]),
      ...mapState({
        currentLanguage: (state) => state.language.currentLanguage,
        products: (state) => state.products.productsOrder
      }),
      vertical() {
        if (this.sreenSize > 601)
          return true
        return false
      }
    },
    methods: {
      ...mapActions(["selectProduct"]),
      myEventHandler(e) {
        this.sreenSize = window.innerWidth;
      },
      clickProduct(product) {
        this.selectProduct(product.product).then(() => {
          this.$router.push({name: 'OrderProduct', params: { name: product.product}})
          }
        )
       // this.$router.push({name: 'OrderProduct', params: { name: product}})
      }
    },
  };
</script>

<style lang="scss" scoped>
@import '@/styles/_variables';
.carousel {

  height: 300px !important;
  border: 1px solid $palette-gray;
  .carousel-item {
    background-color: $palette-0;
  }
}

.button-class {
  color: $black;
  background-color: $white;
  padding: 10px 20px;
  border: 1px solid $border-light;

    &-title {
      color: $body-color;
      font-weight: $font-weight-bolt;
      
    }
  }

  .active-button-class {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding-top: 10px;
    cursor: pointer;

    &-text {
        font-size: $font-size-base !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
  }

  @media (max-width: 600px) {

  }
</style>